/* Меню ТабсИтем*/
import React from 'react';
import './style.css';

class MenuItem extends React.Component {
	render() {
		let { children } = this.props
		return (
			<div>{children}</div>
		)
	}	
}

export default MenuItem;