import React from 'react';
import { Panel, Div, Button, List, Search } from '@vkontakte/vkui';
import Header from '../../components/Header/Header';
import CellBlock from '../../components/CellBlock/CellBlock';
import MenuTabs from '../../components/MenuTabs/MenuTabs'
import MenuItem from '../../components/MenuTabs/MenuItem'


import app from '../../func/app';
import config from '../../config';
import navigation from '../../func/navigation';
import { getAddScope, groupsGet, groupsSearch } from '../../func/vk';


class VerifyGroups extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			globalGroups: [], //группы в поиске
			globalGroupsCount: null, //количество групп которые нашёл
			globalInStr: 20, //отображать на странице
			globalStepInStr: 20, //шаг прибавления

			groups: [], //группы
			groupsCount: null, //колличество групп пользователя
			search: '', //строка поиска
			globalSearch: '', 
			activeItem: 'communityAll', //активное меню

			inStr: 20, //отображать на странице
			stepInStr: 20, //шаг прибавления
		};
	}

	//проверяем есть ли права на группы.
	componentWillMount() {
		let { userScope } = this.props
		if (userScope.indexOf('groups') !== -1) {
			getAddScope(['groups'], (response) => {
				this.getGroups(0, 1000, 0)
			})
		} else {
			this.getGroups(0, 1000, 0)
		}
	}

	getGroups = (offset, count, tik) => {
		const { groups } = this.state
		const { vk_user_id } = config.params
		//получаем группы
		groupsGet(config.access_token, vk_user_id, offset, count, (response) => {
			if (Number(response.count) <= groups.length || tik >= 5) {
				return true
			} else {
				this.setState({
					groups: [...groups, ...response.items],
					groupsCount: +response.count
				}, () => {
					this.getGroups(count+offset, count, tik + 1)
				})
			}
		})
	}

	get groups () {
		const { groups } = this.state
		const search = this.state.search.toLowerCase();
		return groups.filter(({name}) => name.toLowerCase().indexOf(search) > -1);
	}

	get adminGroups () {
		const { groups } = this.state
		const search = this.state.search.toLowerCase();
		return groups.filter(({name, is_admin}) => {
			if (name.toLowerCase().indexOf(search) > -1 && is_admin === 1) {
				return true
			} else {
				return false
			}
		});
	}

	goToOneGroup = (item) => {
		//переход в панель
		app.setState({
			targetGroup: item
		}, () => {
			navigation.go({activePanelSettings: "GroupInfo"})
		})
		
		
	}

	nexPage = () => {
		const { inStr, stepInStr } = this.state
		this.setState({
			inStr: stepInStr + inStr
		})
	}

	nexGlobalPage = () => {
		const { globalStepInStr, globalInStr } = this.state
		this.setState({
			globalInStr: globalStepInStr + globalInStr
		})
	}

	onSearch = (search) => {
		this.setState({ 
			search:search,
			inStr: 20
		});
	}

	onGlobalSearch = (search) => {
		this.setState({ 
			globalSearch:search,
		}, () => {
			groupsSearch(config.access_token, search, 0, 500, (response) => {
				this.setState({
					globalGroups: response.items,
					globalGroupsCount: response.count,
					globalInStr: 20
				})
			})
		});
	}

	renderMenuItemCommunity = (type) => {
		let { inStr, search } = this.state
		return (
			<div>
				<Search theme="default" value={search} onChange={this.onSearch}/>
				<List>
					{
						(type === "all") ?
							this.groups.map((item, i) => {
								let { photo_50, name, id } = item
								return (
									(i < inStr) ? 
										<CellBlock
											key={id}
											photo_50={photo_50}
											title={name}
											onClick={() => {this.goToOneGroup(item)}}
										/>
									: null
								)
							})
						: null
					}
					{
						(type === "admin") ?
							this.adminGroups.map((item, i) => {
								let { photo_50, name, id } = item
								return (
									(i < inStr) ? 
										<CellBlock
											key={id}
											photo_50={photo_50}
											title={name}
											onClick={() => {this.goToOneGroup(item)}}
										/>
									: null
								)
							})
						: null
					}
				</List>
				{
					(type === "all") ?
						(inStr < this.groups.length) ?
							<Div className="flex_center">
								<Button level="secondary" onClick={this.nexPage}>Ещё</Button>
							</Div>
						: null
					: null
				}
				{
					(type === "admin") ?
						(inStr < this.adminGroups.length) ?
							<Div className="flex_center">
								<Button level="secondary" onClick={this.nexPage}>Ещё</Button>
							</Div>
						: null
					: null
				}
			</div>
		)	
	}

	renderGlobalCommunity = () => {
		let { globalInStr, globalSearch, globalGroups, globalGroupsCount } = this.state	
		return (
				<div>
					<Search theme="default" value={globalSearch} onChange={this.onGlobalSearch}/>
					{
						(globalSearch.length === 0) ?
							<Div className="flex_center">
								Начните вводить название группы
							</Div>
						: 
							(globalGroupsCount !== 0) ?
								<List>
									{
										globalGroups.map((item, i) => {
											let { photo_50, name, id } = item
											return (
												(i < globalInStr) ? 
													<CellBlock
														key={id}
														photo_50={photo_50}
														title={name}
														onClick={() => {this.goToOneGroup(item)}}
													/>
												: null
											)
										})
									}
									{
										(globalInStr < globalGroups.length) ?
											<Div className="flex_center">
												<Button level="secondary" onClick={this.nexGlobalPage}>Ещё</Button>
											</Div>
										: null
									}
								</List>
							: <Div className="flex_center">К сожалению, ничего не найдено</Div>
					}
				</div>
		)
	}

	render() {
		console.log(this.state)
		let { activeItem } = this.state
		return (
			<Panel theme="white" id={this.props.id}>
				<Header panelBack={navigation.goBack} name="Сообщества"/>
				<MenuTabs activeItem={activeItem}>

					<MenuItem id="communityAll" title="Все сообщества">
						{this.renderMenuItemCommunity("all")}
					</MenuItem>
					
					<MenuItem id="communityAdmin" title="Управление" >
						{this.renderMenuItemCommunity("admin")}
					</MenuItem>

					<MenuItem id="globalGroups" title="Поиск сообществ" >
						{this.renderGlobalCommunity()}
					</MenuItem>

				</MenuTabs>
			</Panel>
		)
	}
}

export default VerifyGroups;