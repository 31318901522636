import React from 'react';
import { Panel, Div, Button, Alert, Link } from '@vkontakte/vkui';
import Header from '../../components/Header/Header';
import CardPreview from '../../components/CardPreview/CardPreview';

import app from '../../func/app';
import config from '../../config';
import navigation from '../../func/navigation';
import { addToCommunity } from '../../func/vk';

import Number1 from '../../images/numbers/02.png';
import Number2 from '../../images/numbers/01.png';
import Number3 from '../../images/numbers/03.png';
import Number4 from '../../images/numbers/04.png';

class HowGetMoney extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	showAlert = (group_id) => {
		app.setState({ popout:
			<Alert
				actions={[{
					title: 'Отмена',
					autoclose: true,
					style: 'cancel'
				}, {
					title: 'Хорошо',
					autoclose: true,
				}]}
				onClose={() => {
					app.setState({popout: null})
				}}
			  >
				<h2>Отлично</h2>
				<p>Теперь перейдите в приложение по ссылке: <Link target="_blank" href={`https://vk.com/app${config.app_id}_-${group_id}`}>{`https://vk.com/app${config.app_id}_-${group_id}`}</Link></p>
			</Alert>
		});
	}

	installPrilInGroup = () => {
		addToCommunity((response) => {
			if (response.group_id) {
				this.showAlert(response.group_id)
			}
		}, (error) => {
			console.log(error)
		})
	}

	render() {
		console.log(this.state)
		return (
			<Panel theme="white" id={this.props.id}>
				<Header panelBack={navigation.goBack} name="Монетизация"/>
				<Div>
					<center>
					Монетизация сообщества в нашем сервисе возможна прямо с момента его создания, уже с самого первого подписчика!<br/><br/>
					Как это работает:
					</center>
				</Div>
				<CardPreview
					title="Установка приложения."
					text="Установив виджет сервиса в свою группу, Вы получаете новый инструмент альтернативной монетизации сообщества.
Для этого достаточно кликнуть на кнопку внизу страницы и выбрать группу, в которую Вы хотите установить виджет."
					image={Number1}
				/>
				<CardPreview
					title="Фильтрация сообщества."
					text={
						<div>
							Участники сообщества проходят идентификацию (фейк-контроль) в сервисе.<br/>
							Надёжность и достоверность подтверждения обеспечивается проведением банковской транзакции участником с помощью зашифрованных каналов платёжной системы.<br/>
							После этого участник получает статус «Фейк-контроль пройден».<br/>
							ВАЖНО!<br/>
							За участником всегда сохраняется право вернуть свои средства.<br/>
						</div>
					}
					image={Number2}
				/>
				<CardPreview
					title="Вознаграждение."
					text={
						<div>
							Ежемесячно, за каждого подтверждённого участника сообщества, находящегося в статусе «фейк-контроль пройден», сервис выплачивает сообществу вознаграждение.<br/>
							Узнать о том, как рассчитать предварительную сумму возможного вознаграждения можно с помощью группы поддержки сервиса «Фейк-контроль».
						</div>
					}
					image={Number3}
				/>
				<CardPreview
					title="Конкурс."
					text="Каждый месяц Вы проводите конкурс, с любой разрешённой «Вконтакте» механикой, частично или полностью за счёт ежемесячных вознаграждений, поступающих Вам на расчётный счёт или на банковскую карту от сервиса «Фейк-контроль» за фильтрацию Вашего сообщества."
					image={Number4}
				/>
				<Div className="flex_center pt-0">
					<Button onClick={this.installPrilInGroup}>Установить в своё сообщество</Button>
				</Div>
			</Panel>
		)
	}
}

export default HowGetMoney;