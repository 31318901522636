import React from 'react';
import { View, ScreenSpinner, ConfigProvider, Epic, Tabbar, TabbarItem } from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';

//хелперы
import app from './func/app';

import { go } from './func/init';
import { devLog } from './func/utils';
import navigation from './func/navigation';

//стартовые панели
import HomeStartPage from './panels/HomeStartPage';
import HomeSettings from './panels/HomeSettings';

//Welcome Panel
import Welcome from './panels/Welcome/Welcome';

//панели из StartPage
//import CheckMember from './panels/StartPage/CheckMember';

//панели из Settings
import VerifyAccount from './panels/Settings/VerifyAccount';
import VerifyGroups from './panels/Settings/VerifyGroups';
import GroupInfo from './panels/Settings/GroupInfo';
import HowGetMoney from './panels/Settings/HowGetMoney';
import MyMoney from './panels/Settings/MyMoney';


//иконки для эпик меню
import Icon28Newsfeed from '@vkontakte/icons/dist/28/newsfeed';
import Icon28Settings from '@vkontakte/icons/dist/28/settings';

class App extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			scheme: "client_light", //тема оформления client_light, client_dark, bright_light, space_gray
			header: false, //показывает или скрывает хедер для отображения при кастомном хедере на десктопе
			userScope: [], //права пользователя
			isStartInGroup: false, //сервис запущен из группы
			isStartInDesktop: false, //Сервис запущен с компа
			goStartPanel: false, //показывать стартовый экран

			//навигация
			navPanels: {
				activeStory: "settings", //активный эпик вью startpage
				activePanelStartPage: "Home", //активная панель
				activePanelSettings: "Home", //активная панель
			},

			isLoad: false, //загрузка приложения
			popout: null, //всплывающие окна
			apiUsersData: [], //информация о пользователях из апи

			isFake: true, //фекли сам пользователь просматривающий приложение

			targetGroup: {}, //Группа для получения статистики
		};
	}

	componentWillMount() {
		app.setState = (state, callback) => {
			this.setState(state, callback)
		}
		app.state = this.state
		go()
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.data !== this.state) {
			app.state = {
				...this.state
			}
		}
	}

	onStoryChange = (e) => {
		//this.setState({ activeStory: e.currentTarget.dataset.story })
		//navigation.go({activeStory: e.currentTarget.dataset.story})
		navigation.setStory({ activeStory: e.currentTarget.dataset.story })
	}

	render() {
		devLog(this.state)
		let { isLoad, popout, userScope, navPanels, header, scheme, isStartInGroup, isStartInDesktop, goStartPanel, isFake, targetGroup } = this.state
		let { activeStory, activePanelStartPage, activePanelSettings } = navPanels
		if (isLoad) { //сервис уже получил все даннные и загружен
			if (goStartPanel) {
				return (
					<ConfigProvider scheme={scheme} webviewType="vkapps">
						<View header={false} id="components" popout={popout} activePanel={activePanelStartPage}>
							<Welcome isStartInDesktop={isStartInDesktop} id="Home"/>
						</View>
					</ConfigProvider>
				)
			} else {
				return (
					<ConfigProvider scheme={scheme} webviewType="vkapps">
						<Epic activeStory={activeStory} tabbar={
							<Tabbar>
								<TabbarItem
									onClick={this.onStoryChange}
									selected={activeStory === 'settings'}
									data-story="settings"
									text="Личный кабинет"
								>
									<Icon28Settings/>
								</TabbarItem>

								<TabbarItem
									onClick={this.onStoryChange}
									selected={activeStory === 'startpage'}
									data-story="startpage"
									text="Проверка статуса"
								>
									<Icon28Newsfeed/>
								</TabbarItem>
							</Tabbar>
						}>
							<View header={header} id="startpage" popout={popout} activePanel={activePanelStartPage}>
								<HomeStartPage id="Home"/>
								{/* <CheckMember id="CheckMember"/>  */}
							</View>
							<View header={header} id="settings" popout={popout} activePanel={activePanelSettings}>
								<HomeSettings 
									isStartInGroup={isStartInGroup} 
									isFake={isFake} 
									id="Home"
								/>

								<VerifyAccount id="VerifyAccount"/>
								<VerifyGroups userScope={userScope} id="VerifyGroups"/>
								<GroupInfo targetGroup={targetGroup} id="GroupInfo"/>
								<HowGetMoney id="HowGetMoney"/>
								<MyMoney id="MyMoney"/>
							</View>
						</Epic>
					</ConfigProvider>
				);
			}
		} else { // сервис ещё не получил все данные
			return <ScreenSpinner/>
		}
	}
}

export default App;