import config from '../config';

export function sentReq(json_data, callback = () => {}, callback_error = () => {}) {
	let url = config.wsUrl
	if (process.env.NODE_ENV === 'development') {
		 url =  config.devWsUrl
	}
	json_data.params = config.params
	fetch(url, {
		method: 'post',
		headers: {
			'Accept': 'application/json, text/plain, */*',
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		body: 'data=' + encodeURIComponent(JSON.stringify(json_data))
	})
	.then(function (response) {
		return response.json();
	})
	.then((data) => {
		return callback(data);
	})
	.catch (function (error) {
		callback_error(error)
	});
}

export function sentWss(connection, json_data) {
	json_data.params = config.params
	connection.send(JSON.stringify(json_data));
}

//Первый запрос к апи
export function firstQuery(uid, callback = () => {}) {
	const response = {
		response: {
			isFake: true
		}
	}
	callback(response)
}

//Проверка пользователя
export function back_CheckMember(object_id, callback = () => {}) {
	const response = {
		response: {
			isFake: false,
			gid: 1,
			time: 1565304256,
		}
	}
	callback(response)
}

export function back_getOneGroup(gid, callback = () => {}) {
	//тут первые запросы к апи
	const response = {
		response: {
			validMembers: 10,
		}
	}
	callback(response)
}

export function back_getOneGroupAdmin(gid, callback = () => {}) {
	//тут первые запросы к апи
	const response = {
		response: {
			validMembers: 10,
			payouts: [
				{
					time: 1565304256,
					money: 100
				},
				{
					time: 1565304256,
					money: 100
				},
				{
					time: 1565304256,
					money: 100
				},
				{
					time: 1565304256,
					money: 100
				}
			]
		}
	}
	callback(response)
}