import connect from '@vkontakte/vkui-connect';
import config from '../config';
import app from './app';
import { forEachPromise, chunk, unique } from './utils';

function getNewRequestId() {
	return (Math.floor(Math.random() * Number.MAX_SAFE_INTEGER)).toString();
}

export function apiRequest(method, params = {}, accessToken = '', successCallback = undefined, errorCallback = undefined) {
	let requestId = getNewRequestId();
	if (successCallback !== undefined || errorCallback !== undefined) {
		let clb = function callback(e) {
			let vkEvent = e.detail;
			if (!vkEvent) {
				//console.error('invalid event', e);
				return;
			}

			let type = vkEvent['type'];
			let data = vkEvent['data'];

			let found = false;
			if ('VKWebAppCallAPIMethodResult' === type && data['request_id'] === requestId) {
				if (successCallback !== undefined) {
					successCallback(data['response']);
				}

				found = true;
			} else if ('VKWebAppCallAPIMethodFailed' === type && data['request_id'] === requestId) {
				if (errorCallback !== undefined) {
					errorCallback(data);
				}

				found = true;
			}

			if (found) {
				connect.unsubscribe(clb);
			}

		};

		connect.subscribe(clb);
	}

	params['access_token'] = accessToken;

	if (params['v'] === undefined) {
		params['v'] = config.api_version;
	}

	connect.send('VKWebAppCallAPIMethod', {
		'method': method,
		'params': params,
		'request_id': requestId
	});
}

//получение токена пользователя
export function getToken(scope, successCallback = () => {}, errorCallback = () => {}) {
	let clb = (e) => {
		if (Object.keys(e.detail).length > 0) {
			switch (e.detail.type) {
				case 'VKWebAppAccessTokenReceived': //токен получен.
					successCallback(e.detail.data); 
					connect.unsubscribe(clb);
				break;
				case 'VKWebAppAccessTokenFailed': //токен не получен.
					errorCallback(true);
					connect.unsubscribe(clb);
				break;
				default:
				//
			}
		}
	}
	connect.subscribe(clb);
	connect.send("VKWebAppGetAuthToken", {
		"app_id": config.app_id, 
		"scope": scope.join(',')
	});
}

//получение токена по текущим правам доступа пользователя
export function getStartToken(scope, callback) {
	let newScope = [];
	scope.forEach((item) => {
		if ( item !== "" && item !== "menu" && item !== "notify") { 
			newScope.push(item) 
		}
	})
	if (newScope.length > 0) {
		getToken(newScope, (response) => {
			callback(response)
		}, (error)=> {
			callback(false);
		})
	} else {
		callback(false)
	}
}

//расширяет права доступа пользователя
export function getAddScope(scope, callback) {
	let { userScope } = app.state
	let newScope = scope.concat(...userScope)
	getToken(newScope, (response) => {
		const { access_token } = response
		config.set({access_token:access_token}, () => {
			callback(response)
		})
	})
}

//пользователь разрешает писать сообществу
export function getAllowMessagesFromGroup(gid, successCallback = () => {}, errorCallback = () => {}) {

	let clb = (e) => {
		if (Object.keys(e.detail).length > 0) {
			switch (e.detail.type) {
				case 'VKWebAppAllowMessagesFromGroupResult': //пользователь разрешил отправлять себе сообщения от имени группы
					successCallback(e.detail.data); 
					connect.unsubscribe(clb);
				break;
				case 'VKWebAppAllowMessagesFromGroupFailed': //отмена
					errorCallback(e.detail.data); 
					connect.unsubscribe(clb);
				break;
				default:
				//
			}
		}
	}

	connect.subscribe(clb);
	connect.send("VKWebAppAllowMessagesFromGroup", { "group_id": gid });
}

//вызывает окно оплаты VK Pay для оплаты
export function getVkPay(amount, description, successCallback = () => {}, errorCallback = () => {}) {
	let clb = (e) => {
		if (Object.keys(e.detail).length > 0) {
			switch (e.detail.type) {
				case 'VKWebAppOpenPayFormResult':
					connect.unsubscribe(clb); 
					successCallback(e.detail.data);
				break;
				case 'VKWebAppOpenPayFormFailed':
					connect.unsubscribe(clb); 
					errorCallback(e.detail.data);
				break;
				default:
				//
			}
		}
	}
	connect.subscribe(clb);
	connect.send("VKWebAppOpenPayForm", {
		"app_id": config.app_id,
		"action": "pay-to-group",
		"params": {
			"amount": amount,
			"description": description,
			"group_id": config.group_id,
		}
	});
}

//получение информации о текущем пользователе
export function getUserInfo(callback) {
	let clb = (e) => {
		if (Object.keys(e.detail).length > 0) {
			switch (e.detail.type) {
				case 'VKWebAppGetUserInfoResult':
					callback(e.detail.data);
				break;
				default:
					//
			}
		}
	}
	connect.subscribe(clb);
	connect.send('VKWebAppGetUserInfo', {});
}

//получение ключа доступа сообщества
export function getCommunityAuthToken(gid, scope, successCallback = () => {}, errorCallback = () => {}) {
	let clb = (e) => {
		if (Object.keys(e.detail).length > 0) {
			switch (e.detail.type) {
				case 'VKWebAppGetCommunityAuthTokenResult':
					connect.unsubscribe(clb); 
					successCallback(e.detail.data);
				break;
				case 'VKWebAppGetCommunityAuthTokenFailed':
					connect.unsubscribe(clb); 
					errorCallback(e.detail.data);
				break;
				default:
				//
			}
		}
	}
	connect.subscribe(clb);
	connect.send("VKWebAppGetCommunityAuthToken", {"app_id": config.app_id, "group_id": gid, "scope": scope.join(',')});
}

//вызов нативного просмоторщика фотографий
//images - массив ссылок на фото
export function showImagesBox(images = [], errorCallback = () => {}) {
	let clb = (e) => {
		if (Object.keys(e.detail).length > 0) {
			switch (e.detail.type) {
				case 'VKWebAppShowImagesFailed':
					connect.unsubscribe(clb); 
					errorCallback(e.detail.data);
				break;
				default:
				//
			}
		}
	}
	connect.subscribe(clb);
	connect.send("VKWebAppShowImages", { 
		images: images
	});
}

//выбор контакта из телефонной книги
export function getPhoneContacts(successCallback = () => {}, errorCallback = () => {}) {
	let clb = (e) => {
		if (Object.keys(e.detail).length > 0) {
			switch (e.detail.type) {
				case 'VKWebAppContactsDone':
					connect.unsubscribe(clb); 
					successCallback(e.detail.data);
				break;
				case 'VKWebAppContactsClosed':
					connect.unsubscribe(clb); 
					errorCallback(e.detail.data);
				break;

				default:
				//
			}
		}
	}
	connect.subscribe(clb);
	connect.send("VKWebAppOpenContacts", {});
}

//Вызов списка друзей пользователя
export function getFriends(successCallback = () => {}, errorCallback = () => {}) {
	let clb = (e) => {
		if (Object.keys(e.detail).length > 0) {
			switch (e.detail.type) {
				case 'VKWebAppGetFriendsResult':
					connect.unsubscribe(clb); 
					successCallback(e.detail.data);
				break;
				case 'VKWebAppGetFriendsFailed':
					connect.unsubscribe(clb); 
					errorCallback(e.detail.data);
				break;

				default:
				//
			}
		}
	}
	connect.subscribe(clb);
	connect.send("VKWebAppGetFriends", {});
}

//iOS, Android
//Вызов камеры для сканирования QR 
export function webAppOpenQR(successCallback = () => {}, errorCallback = () => {}) {
	let clb = (e) => {
		if (Object.keys(e.detail).length > 0) {
			switch (e.detail.type) {
				case 'VKWebAppOpenQRResult':
					connect.unsubscribe(clb); 
					successCallback(e.detail.data);
				break;
				case 'VKWebAppOpenQRFailed':
					connect.unsubscribe(clb); 
					errorCallback(e.detail.data);
				break;

				default:
				//
			}
		}
	}
	connect.subscribe(clb);
	connect.send("VKWebAppOpenQR");
}

//Добавление сервиса в сообщество
export function addToCommunity(successCallback = () => {}, errorCallback = () => {}) {
	let clb = (e) => {
		if (Object.keys(e.detail).length > 0) {
			switch (e.detail.type) {
				case 'VKWebAppAddToCommunityResult':
					connect.unsubscribe(clb); 
					successCallback(e.detail.data);
				break;
				case 'VKWebAppAddToCommunityFailed':
					connect.unsubscribe(clb); 
					errorCallback(e.detail.data);
				break;

				default:
				//
			}
		}
	}
	
	connect.subscribe(clb);
	connect.send("VKWebAppAddToCommunity", {});
}

/* VK API */
//получение данных о пользователях
export function apiGetUser(access_token, uids, callback) {
	let arr = chunk(uids, 500);
	let arrToSend = [];
	forEachPromise(arr, (item) => {
		return new Promise((resolve, reject) => {
			try {
				apiRequest("users.get",{
					user_ids: item.join(','),
					fields: config.userFields.join(',')
				}, access_token, (resp) => {
					arrToSend.push(...resp)
					resolve()
				})
			} catch (err) {
				console.log('Ошибка users.get 584', err)
			}
		})
	}).then(() => {
		callback(arrToSend)
	});
}

//получение данных по недостающим пользователям 
// availableUids - массив пользователей которые уже есть 
// needUids - массив пользователей по которым нужно получить данные
export function getMissingUsers(availableUids = [], access_token, needUids = [], callback = () => {}) {
	let uids = [];

	needUids.forEach((uid) => {
		if (availableUids.indexOf(uid) === -1) {
			availableUids.push(uid)
			uids.push(uid)
		}
	})

	if (uids.length > 0) {
		apiGetUser(access_token, unique(uids), (apiResponse) => {
			callback(apiResponse)
		})
	} else {
		callback(false)
	}
}

export function resolveScreenName(access_token, screen_name, callback) {
	apiRequest("utils.resolveScreenName",{
		screen_name: screen_name
	}, access_token, (response) => {
		callback(response)
	})
}

//получаем группы
export function groupsGet(access_token, user_id, offset, count, callback) {
	apiRequest("groups.get",{
		extended: 1,
		user_id: user_id,
		offset: offset,
		count: count,
		fields: "members_count"
	}, access_token, (response) => {
		callback(response)
	})
}

//получаем информацию о группе
export function groupsGetById(access_token, group_id, callback, callback_error) {
	apiRequest("groups.getById",{
		group_id: group_id,
		fields: "members_count"
	}, access_token, (response) => {
		callback(response)
	}, (error) => {
		callback_error(error)
	})
}

//Ищем группы
export function groupsSearch(access_token, q, offset, count, callback) {
	apiRequest("groups.search",{
		q: q,
		offset: offset,
		count: count,
		fields: "members_count"
	}, access_token, (response) => {
		callback(response)
	})
}


