/* Блок для вывода сообщества или пользователя */
import React from 'react';
import { Cell, Avatar } from '@vkontakte/vkui';
import Icon16Verified from '@vkontakte/icons/dist/16/verified';

import './style.css';

class CellBlock extends React.Component {
	render() {
		let { online, online_mobile, photo_50, href, description, title, verified, onClick } = this.props
		return (
			<Cell 
				before={
					<Avatar className={(online) ? `online ${(online_mobile) ? "online_mobile" : null}` : null} src={photo_50} size={48} />
				}
				href={(onClick) ? null : href}
				target={(onClick) ? null : `_blank`}
				onClick={onClick}
				description={description}
				multiline={true}
			>
				<div className="BlockName">{title} {(verified === 1) ? <Icon16Verified className="VerifiedIcon"/> : null}</div>
			</Cell>
		);
	}
}

export default CellBlock;