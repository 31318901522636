import React from 'react'

export function devErrorLog(e) {
	if (process.env.NODE_ENV === 'development') {
		console.error(e)
	}
}

export function devLog(any) {
	if (process.env.NODE_ENV === 'development') {
		console.log(any)
	}
}

export function throwDevError(error) {
	if (process.env.NODE_ENV === 'development') {
		throw error
	}
}

export function isRetina() {
	return window.devicePixelRatio > 1
}

export function getHash() {
		let itog;
		let params = window
		.location
		.href
		.replace('?','')
		.split('#')

		if (params[1]) {
			itog = params[1]
				.replace('#','')
				.split('&')
				.reduce(
					function(p,e){
						var a = e.split('=');
						p[ decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
						return p;
					},
					{}
				);
		}
		
		if (itog) {
			return itog;
		} else {
			return false;
		}
}

export function removeLink(text) {
	// eslint-disable-next-line
	const regexp = /(\[[a-zA-Z\@\:\/\/\.0-9\-_]+\|.*?\]|<.*?>)/gmu
	let parts = text.split(regexp)
	let res = []
	parts.forEach( (t,i) => {
		if (t.indexOf('[') === 0) {
			let tag = t.split('|')
			res.push(tag[1].replace(']', ''))
		} else {
			res.push(t)
		}
	})
	let str = '';
	res.forEach((e,i) => {
		if (e) {
			str += e
		}
	})
	return str;
}

function parseLink(text,pref,cfg = {}) {
	// eslint-disable-next-line
	const regexp = /(\[[a-zA-Z\@\:\/\/\.0-9\-_]+\|.*?\]|<.*?>)/gmu

	let parts = text.split(regexp)
	if (parts.length === 1) {
		return Linkify(parts[0])
	}
	let res = []
	parts.forEach( (t,i) => {
		if (t.indexOf('[') === 0 && !cfg['noLink']) {
			const linkPrefix = /(((https|http):\/\/)?(www\.)?)/
			let tag = t.split('|')
			let href = 'http://' + tag[0].replace('[', '').replace(linkPrefix, '')
			let text = tag[1].replace(']', '')
			res.push(<a className="otherLink" rel="noopener noreferrer" href={href} target="_blank" key={pref + '_' + i}>{text}</a>)
		} else if (t.indexOf('<link') === 0) {
			let tag = t.split('|')
			let href = tag[0].replace('<link', '')
			let text = tag[1].replace('link>', '')
			res.push(<a className="otherLink" href={href} target="_blank" rel="noopener noreferrer" key={pref + '_' + i}>{text}</a>)
		} else if (t.indexOf('<') === 0 && !cfg['noStrong']) {
			res.push(<strong key={pref + '_' + i}>{t.substr(1, t.length-2)}</strong>)
		} else {
			res.push(Linkify(t))
		}
	} )
	
	return res
}

function Linkify (message) {
	const messageBlock = message.replace(/\r|\n/g, ' ').split(' ');
	// eslint-disable-next-line
	const regExp = [/^(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,/(^|[^\/])(www\.[\S]+(\b|$))/gim,/[a-z0-9-\.]+\.[a-z]{2,4}\/?([^\s<>\#%"\,\{\}\\|\\\^\[\]`]+)?$/];
	const pref = 'Linkify';
	let domEnd = messageBlock.map((m,i) => {
			return regExp[0].test(m)
				? <a className="otherLink" href={m} target="_blank" rel="noopener noreferrer" key={pref + '_' + i}>{m}</a>
				: regExp[1].test(m)
						? [<a className="otherLink" href={"http://"+m} target="_blank" rel="noopener noreferrer" key={pref + '_' + i}>{m}</a>, ' ']
						: regExp[2].test(m)
							? [<a className="otherLink" href={"http://"+m} target="_blank" rel="noopener noreferrer" key={pref + '_' + i}>{m}</a>, ' ']
							: ` ${m} `
		})
	return domEnd
}


export function nToBr(string, cfg = {}) {
	string = string || ""
	if (!cfg['noTypography']) {
		string = string.replace(/&shy;/g, "\u00AD")
		string = string.replace(/&nbsp;/g, "\u00A0")
		string = string.replace(/&#8209;/g, "\u2011")
	}
	let stringArray;
	if (cfg['oneStr']) {
		stringArray = [string];
	} else {
		stringArray = string.split('\n')
	}
	let length = stringArray.length
	let result = []
	for (let i = 0; i < length; i++) {
		result.push(parseLink(stringArray[i], i, cfg))
		if (i !== length - 1) {
			result.push(<br key={i}/>)
		}
	}
	return result
}

export function desktopShare(url, image, title, comment = '') {
	let params = {
		title: title,
		image: image,
		noparse: "1",
		comment: comment
	};

	let popupName = '_blank',
		width = 650,
		height = 610,
		left = Math.max(0, (window.screen.width - width) / 2),
		top = Math.max(0, (window.screen.height - height) / 2),
		surl = 'https://vk.com/share.php?url=' + encodeURIComponent(url),
		popupParams = 'width='+width+',height='+height+',left='+left+',top='+top+',menubar=0,toolbar=0,location=0,status=0',
		popup = false;

	try {
		let doc_dom = '', loc_hos = '';
		try {
			doc_dom = document.domain;
			loc_hos = window.location.host;
		} catch (e) {
		}
		if (doc_dom !== loc_hos) {
			let ua = window.navigator.userAgent.toLowerCase();
			if (!/opera/i.test(ua) && /msie/i.test(ua)) {
				this._inlineShare(popup, surl, params)
			}
		}
		popup = window.open('', popupName, popupParams);
		let text = '<form accept-charset="UTF-8" action="' + surl + '" method="POST" id="share_form">';
		for (let i in params) {
			text += '<input type="hidden" name="' + i + '" value="' + params[i].toString().replace(/"/g, '&myquot;').replace(/&quot/ig, '&myquot') + '" />';
		}
		text += '</form>';
		text += '<script type="text/javascript">document.getElementById("share_form").submit()</script>';

		text = '<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">' +
			'<html xmlns="http://www.w3.org/1999/xhtml" xml:lang="en" lang="en">' +
			'<head><meta http-equiv="content-type" content="text/html; charset=UTF-8" /></head>' +
			'<body>' + text + '</body></html>';
		popup.document.write(text);
		popup.focus();
	} catch (e) { // ie with changed domain.
		_inlineShare(popup, surl, params, popupName, popupParams)
	}
}

function _inlineShare(popup, surl, params, popupName, popupParams) {
	try {
		if (popup) {
			popup.close();
		}
		surl += '?';
		for (let i in params) {
			surl += encodeURIComponent(i) + '=' + encodeURIComponent(params[i]) + '&';
		}
		popup = window.open(surl, popupName, popupParams);
		popup.focus();
	} catch (e) {
		alert("Sharing error: " + e.message)
	}
}


export function getAndroidVersion() {
	let ua = (window.navigator.userAgent).toLowerCase();
	// eslint-disable-next-line
	let match = ua.match(/android\s([0-9\.]*)/);
	if (ua.indexOf('chrome/6') !== -1) {
		return false
	}
	return match ? match[1] : false
}

export function getIosVersion() {
	if (/iP(hone|od|ad)/.test(navigator.platform)) {
		let v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
		return parseInt(v[1], 10)
	} else {
		return false
	}
}

export function getParams() {
		let params = window
		.location
		.search
		.replace('?','')
		.split('&')
		.reduce(
			function(p,e){
				var a = e.split('=');
				p[ decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
				return p;
			},
			{}
		);

		return params;
}

//сортирует объект по ключу
export function dynamicSort(property) {
	let sortOrder = 1;
	if(property[0] === "-") {
		sortOrder = -1;
		property = property.substr(1);
	}
	return function (a,b) {
		let result = (a[property] > b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
		return result * sortOrder;
	}
}

//разбивает массив на чанки
export function chunk(arr, chunkSize) {
	let R = [];
	for (let i=0; i<arr.length; i+=chunkSize)
			R.push(arr.slice(i,i+chunkSize));
		return R;
}


export function forEachPromise(items, fn) {
	return items.reduce(function (promise, item) {
		return promise.then(function () {
			return fn(item);
		});
	}, Promise.resolve());
}

export function unique(arr) {
  let obj = {};

  for (let i = 0; i < arr.length; i++) {
	let str = arr[i];
	obj[str] = true;
  }
  return Object.keys(obj);
}