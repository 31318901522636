let config = {
	app_id: null, //ид приложения
	admin_app: [152873202, 212705339, 33873307], //админы
	group_id: 180857475,//группа для сообщений
	params: null, //параметры запуска
	hash: null, //Хеш приложения при запуске
	userData: null, //Данные о пользователе vk api
	wsUrl: (process.env.NODE_ENV === 'development') ? 'wss://echo.websocket.org' : 'wss://echo.websocket.org', //url wss сервера
	api_version: '5.87', //версия апи
	access_token: 'd4306b7cd4306b7cd4306b7c1ad45b4dcedd430d4306b7c8935567e4c2044f3059147b2', //ключ доступа апи
	desktopHeader: true, //кастомный хедер для десктопа
	groupChange: true, //другой сервис в группе. true если нужно загружать другие панели для сервиса запущенного в группе
	startPanel: true, //показывать стартовую панель. Если true будет 1 раз загружать стартовую панель, если сервис загружен не в группе.  
	userFields: ['photo_50', 'online', 'verified', 'sex'], //данные которые нужно запрашивать у пользователей
	set: function(data, callback = () => {}) { //смена конфига
		for (let key in data) {
			this[key] = data[key]
		}
		callback(true)
	}
}

export default config;