import React from 'react';
import { Panel, Group, List, Cell, Alert } from '@vkontakte/vkui';
import Header from '../components/Header/Header';
import CellBlock from '../components/CellBlock/CellBlock';

import app from '../func/app';
import config from '../config';
import navigation from '../func/navigation';
import { getAllowMessagesFromGroup } from '../func/vk';

//иконки
import Icon24Done from '@vkontakte/icons/dist/24/done';
import Icon24Users from '@vkontakte/icons/dist/24/users';
import Icon24Coins from '@vkontakte/icons/dist/24/coins';
import Icon24Forward10 from '@vkontakte/icons/dist/24/forward_10';
import Icon24MoneyTransfer from '@vkontakte/icons/dist/24/money_transfer';

class HomeSettings extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	showAlert = () => {
		app.setState({ popout:
			<Alert
				actions={[{
					title: 'Отмена',
					autoclose: true,
					style: 'cancel'
				}, {
					title: 'Хорошо',
					autoclose: true,
				}]}
				onClose={() => {
					app.setState({popout: null})
				}}
			>
				<h2>Отлично</h2>
				<p>Вся информация по возврату средств отправлена Вам в личные сообщения.</p>
			</Alert>
		});
	}

	goMoneyBack = () => {
		getAllowMessagesFromGroup(+config.group_id, (response) => {
			if (response.result) {
				this.showAlert()
			}
		}, (error) => {
			console.log(error)
		})
	}

	render() {
		let { isFake, isStartInGroup } = this.props
		const { params } = config
		const { id, first_name, last_name, photo_100 } = config.userData
		console.log(isFake)
		return (
			<Panel theme="white" id={this.props.id}>
				<Header name="Фейк-контроль"/>
				<Group>
					{
						(isFake) ?
							<CellBlock
								online={1}
								photo_50={photo_100}
								href={`https://vk.com/id${id}`}
								title={`${first_name} ${last_name}`}
								verified={0}
								description='Вы ещё не проходили фейк-контроль!'
							/>
						: 
							<CellBlock
								online={1}
								photo_50={photo_100}
								href={`https://vk.com/id${id}`}
								title={`${first_name} ${last_name}`}
								verified={1}
								description='Фейк-контроль пройден'
							/>
					}
					<List>
						{
							(isFake) ?
								<Cell expandable before={<Icon24Done fill="var(--button_commerce_background)"/>} onClick={() => { navigation.go({activePanelSettings: "VerifyAccount"}) }}>Пройти фейк-контроль</Cell>
							: null
								
						}
						
						{
							(isStartInGroup) ? null : 
								<Cell expandable before={<Icon24Users fill="var(--button_primary_background)"/>} onClick={() => { navigation.go({activePanelSettings: "VerifyGroups"}) }}>Сообщества</Cell>
						}
						{
							(params.vk_viewer_group_role === "admin") ?
								<Cell expandable before={<Icon24MoneyTransfer fill="var(--button_primary_background)"/>} onClick={() => { navigation.go({activePanelSettings: "MyMoney"}) }}>Доходы сообщества</Cell>
							: null
						}
						<Cell expandable before={<Icon24Coins fill="var(--button_primary_background)"/>} onClick={() => { navigation.go({activePanelSettings: "HowGetMoney"}) }}>Монетизация сообщества</Cell>
						{
							(!isFake) ? 
								<Cell expandable before={<Icon24Forward10 fill="var(--button_primary_background)"/>} onClick={this.goMoneyBack}>Запросить возврат</Cell>
							: null
						}
					</List>
				</Group>
			</Panel>
		)
	}
}

export default HomeSettings;