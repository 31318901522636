import React from 'react';
import { Panel, Div, Button, Gallery } from '@vkontakte/vkui';
import app from '../../func/app';
import config from '../../config';
import { getAddScope } from '../../func/vk';

import './style.css';

import TreeScreen1 from '../../images/export_1-01.png';
import TreeScreen2 from '../../images/ekran_2_gotov-01.png';
import TreeScreen3 from '../../images/ekran_3-01.png';

class Welcome extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			slideIndex: 0
		};
	}

	renderButton = (slideIndex) => {
		switch (slideIndex) {
			case 0:
				return (
					<Button 
						onClick={() => this.setState({slideIndex: slideIndex === 2 ? 2 : slideIndex + 1 })}
					>
						Далее
					</Button>
				)
			case 1:
				return (
					<Button 
						onClick={() => this.setState({slideIndex: slideIndex === 2 ? 2 : slideIndex + 1 })}
					>
						Далее
					</Button>
				)
			case 2:
				return (
					<Button 
						onClick={this.startPril}
					>
						Начать
					</Button>
				)
			default:
				//
		}
	}

	startPril = () => {
		//запросить права.
		getAddScope(["groups"], (response) => {
			config.set({access_token: response.access_token}, () => {
				app.setState({
					userScope: response.scope.split(','),
					goStartPanel: false,
				}, () => {
					localStorage.setItem('welcome', 'true')
				})
			})
		})
	}

	isPhone = () => {
		const { params } = config
		const { vk_platform } = params
		return vk_platform
	}

	render() {
		let { slideIndex } = this.state
		let { isStartInDesktop, id } = this.props
		return (
			<Panel theme="white" id={id}>

					<Gallery
						slideWidth="100%"
						align="center"
						className="WelcomeGallery"
						slideIndex={slideIndex}
						onChange={slideIndex => this.setState({slideIndex})}
						autoplay={0}
						bullets={false}
					>
						<div className="WelcomeOneBlock">
							<div className={(isStartInDesktop) ? `WelcomeTextBlock_desktop` : `WelcomeTextBlock`}>
								<div className="WelcomeTitle">
									<span role="img" aria-label="star" >⭐</span> Монетизируйте своё сообщество уже с самого первого подписчика.
								</div>
							</div>
							<div className="WelcomeImg">
								<img className={(this.isPhone() === 'desktop_web') ? `WelcomeImgImg` : `WelcomeImgImg_phone`} alt="" src={TreeScreen1}/>
							</div>
						</div>

						<div className="WelcomeOneBlock">
							<div className={(isStartInDesktop) ? `WelcomeTextBlock_desktop` : `WelcomeTextBlock`}>
								<div className="WelcomeTitle">
									<span role="img" aria-label="star" >⭐</span> Получайте ежемесячное вознаграждение за каждого реального подписчика!
								</div>
							</div>
							<div className="WelcomeImg">
								<img className={(this.isPhone() === 'desktop_web') ? `WelcomeImgImg` : `WelcomeImgImg_phone`} alt="" src={TreeScreen2}/>
							</div>
						</div>

						<div className="WelcomeOneBlock">
							<div className={(isStartInDesktop) ? `WelcomeTextBlock_desktop` : `WelcomeTextBlock`}>
								<div className="WelcomeTitle">
									<span role="img" aria-label="star" >⭐</span> Проводите конкурсы для своих подписчиков за счёт сервиса «Фейк-контроль»! 
								</div>
							</div>
							<div className="WelcomeImg">
								<img className={(this.isPhone() === 'desktop_web') ? `WelcomeImgImg` : `WelcomeImgImg_phone`} alt="" src={TreeScreen3}/>
							</div>
						</div>
	
					</Gallery>
					<Div className="flex_center">
						{ 
							this.renderButton(slideIndex) 
						}
					</Div>

			</Panel>
		)
	}
}

export default Welcome;