/* Блок для предварительного показа карточек с текстом, картинкой и коротким описанием */
import React from 'react';
import { Group, Div } from '@vkontakte/vkui';
import config from '../../config';

import './style.css';

class CardPreview extends React.Component {
	render() {
		let { title, text, image, thirdblock, description } = this.props
		const { vk_platform } = config.params
		if (vk_platform === "desktop_web") {
			return (
				<Group description={<div className="flex_end">{description}</div>}>
					<Div className={(description) ? `pb-0` : null}>
						<div className="CardPreviewBlock_desc">
							<img alt="" className="CardPreviewImg_desc" src={image}/>
							<div>
								<div className="CardPreviewTitle_desc">{title}</div>
								<div className="CardPreviewText_desc">{text}</div>
								<div className="CardPreviewDownBlock_desc">
									{thirdblock}
								</div>
							</div>
						</div>
					</Div>
				</Group>
			);
		} else {
			return (
				<Group description={<div className="flex_start">{description}</div>}>
					<Div className={(description) ? `pb-0` : null}>
						<div className="CardPreviewTitle_mob">{title}</div>
						<div className="flex_center">
							<img alt="" className="CardPreviewImg_mob" src={image}/>
						</div>
						<div className="CardPreviewText_desc">{text}</div>
						<div className="CardPreviewDownBlock_desc">
							{thirdblock}
						</div>
					</Div>
				</Group>
			);
		}
	}
}

export default CardPreview;