import app from './app';
import config from '../config';
import navigation from './navigation';
//import wss from './wss';
import { firstQuery } from './back';
import { getParams, getHash } from './utils';
import { getUserInfo, getStartToken } from './vk';
import connect from '@vkontakte/vkui-connect';

export function go(callback) {

	// подписываемся на VKWebAppUpdateConfig
	connect.subscribe((e) => {
		switch (e.detail.type) {
			case 'VKWebAppUpdateConfig':
				let scheme = e.detail.data.scheme ? e.detail.data.scheme : 'client_light';
				app.setState({
					scheme: scheme
				})
			break;
			default:
				//
		}
	});


	window.onpopstate = (e) => {
		navigation.nativeGoBack()
		return;
	}

	const params = getParams()
	const hash = getHash()

	config.set({
		params: params,
		hash: hash,
		app_id: +params.vk_app_id
	}, () => {
		//wss.init(() => { //если нужно конектится к сокету
			startApp()
		//}) 
	})
}

function startApp() {
	getUserInfo((user) => {
		getStartToken(config.params.vk_access_token_settings.split(','), (token) => {
			firstQuery(config.params.vk_user_id, (response) => { //первый запрос к беку
				setConfigAndState(token, response, user)
			})
		})
	})
}

function setConfigAndState(token, firstAnswerBack, userData) {
	if (token) { //сценарий где токен получен
		config.set({access_token: token.access_token, userData: userData}, () => {
			app.setState({
				userScope: token.scope.split(','),
				isLoad: true,
				header: (config.params.vk_platform === "desktop_web" && config.desktopHeader) ? false : true, //показываем или скриваем хедер при кастомном хедере на десктопе 
				isStartInGroup: isStartInGroup(),
				goStartPanel: isStartPanel(),
				isStartInDesktop: isStartInDesktop(),
				isFake: firstAnswerBack.response.isFake
			})
		})
	} else { //сценарий где токен не получен
		config.set({userData: userData}, () => {
			app.setState({
				isLoad: true,
				header: (config.params.vk_platform === "desktop_web" && config.desktopHeader) ? false : true, //показываем или скриваем хедер при кастомном хедере на десктопе 
				isStartInGroup: isStartInGroup(),
				goStartPanel: isStartPanel(),
				isStartInDesktop: isStartInDesktop(),
				isFake: firstAnswerBack.response.isFake
			})
		})
	}
}

function isStartPanel() {
	//проверить localstorage
	if (isStartInGroup()) {
		return false
	} else {
		let welcome = localStorage.getItem('welcome')
		if (!welcome && config.startPanel) {
			return true
		} else {
			return false
		}
	}
}

function isStartInGroup() {
	return (config.params.vk_group_id) ? true : false
}

function isStartInDesktop() {
	return (config.params.vk_platform === "desktop_web") ? true : false
}