import React from 'react';
import { Panel, Group, Div, FormLayout, Input, Button, Spinner } from '@vkontakte/vkui';
import Header from '../components/Header/Header';
import CellBlock from '../components/CellBlock/CellBlock';

import config from '../config';
import { resolveScreenName, apiGetUser, groupsGetById } from '../func/vk';
import { back_CheckMember } from '../func/back';

import moment from "moment";

class HomeStartPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			userLink: "",
			validUserLink: true,
			showResult: false,
			showSpinner: false,
			disabledButton: false,
			groupTitle: "Подождите, проверяю пользователя",

			userInfoVk: {},
			groupInfoVk: {},
			isFake: false,
			time: 0,
		};
	}

	onChange = (e) => {
		const { name, value } = e.currentTarget;
		this.setState({ 
			[name]: value,
			validUserLink: true
		});
	}

	clearUserLink = (userLink) => {
		return userLink.replace(/https:\/\//g, "")
				.replace(/http:\/\//g, "")
				.replace(/m\.vk\.com\//g, "")
				.replace(/vk\.com\//g, "")
	}

	checkMember = () => {
		let { userLink } = this.state
		if (userLink) { //есть текст
			//проверяем перед нами пользователь или нет
			let screen_name = this.clearUserLink(userLink)

			resolveScreenName(config.access_token, screen_name, (response) => {
				const { type, object_id } = response
				if (type === 'user') {
					this.setState({
						showResult: true,
						showSpinner: true,
						disabledButton: true,
						isFake: false
					}, () => {
						//запрос на сервер.
						back_CheckMember(object_id, (answer) => {
							const { isFake, gid, time } = answer.response
							groupsGetById(config.access_token, gid, (groupInfoVk) => {
								//получение информации из апи
								apiGetUser(config.access_token, [object_id], (userInfoVk) => {
									this.setState({
										userInfoVk: userInfoVk[0],
										groupInfoVk: groupInfoVk[0],
										isFake: isFake,
										showSpinner: false,
										disabledButton: false,
										groupTitle: (isFake) ? "Фейк-контроль не пройден" : "Фейк-контроль пройден",
										time: time
									})
								})
							})

						
						})
					})
				} else {
					this.setState({
						validUserLink: false,
					})
				}
			})
		} else {
			this.setState({
				validUserLink: false
			})
		}
	}

	render() {
		let { userLink, validUserLink, showResult, showSpinner, groupTitle, disabledButton, userInfoVk, groupInfoVk, isFake, time } = this.state
		let { online, online_mobile, photo_50, id, first_name, last_name } = userInfoVk
		return (
			<Panel theme="white" id={this.props.id}>
				<Header name="Проверка статуса"/>
				<Group>
				{
					/*
						<Div>
							Если Вы проводите конкурс, призовым фондом которого (полностью или частично) является вознаграждение сервиса за фильтрацию Вашего сообщества, то Вам необходимо проверить победителя Вашего конкурса.
						</Div>
						<Div className="pt-0">
							Ваш победитель:<br/>
							1. Является подписчиком Вашего сообщества, в котором Вы проводите конкурс?<br/>
							2. Прошёл фейк-контроль в Вашем сообществе?
							3. Находится в статусе «Фейк-контроль пройден»?
						</Div>
						<Div className="pt-0">
							Если все условия соблюдены, скорее вознаградите такого победителя!
						</Div>
					*/
				}
					<Div>
						Здесь Вы можете проверить прошёл ли фейк-контроль:<br/>
					</Div>
					<Div className="pt-0">
						<span role="img" aria-label="star">⭐</span> победитель Вашего конкурса<br/>
						<span role="img" aria-label="star">⭐</span> пользователь, предложивший Вам дружбу<br/>
						<span role="img" aria-label="star">⭐</span> случайный собеседник, которого Вы не знаете<br/>
					</Div>
					<FormLayout>
						<Input
							placeholder="https://vk.com/id1"
							type="text"
							top="Введите ссылку на пользователя"
							name="userLink"
							value={userLink}
							status={validUserLink ? 'default' : 'error'}
							onChange={this.onChange}
							bottom={validUserLink ? '' : 'Это ссылка не на пользователя ВКонтакте'}
						/>
					</FormLayout>
					<Div className="flex_end pt-0">
						<Button disabled={disabledButton} onClick={this.checkMember}>Проверить</Button>
					</Div>
				</Group>
				{
					(showResult) ? 
						<Group title={groupTitle}>
							{
								(showSpinner) ? 
									<Spinner size="regular" /> 
								: 
									<div>
										{
											(isFake) ?
												<CellBlock
													online={online}
													online_mobile={online_mobile}
													photo_50={photo_50}
													href={`https://vk.com/id${id}`}
													title={`${first_name} ${last_name}`}
													verified={0}
													description='Вероятно это фейк!'
												/>
											: 
												<CellBlock
													online={online}
													online_mobile={online_mobile}
													photo_50={photo_50}
													href={`https://vk.com/id${id}`}
													title={`${first_name} ${last_name}`}
													verified={1}
													description={`Фейк-контроль пройден в сообществе «${groupInfoVk.name}»:  ${moment(moment.unix(time), 'DD/MM/YYYY', true).format("DD.MM.YYYYг")}`}
												/>
										}
									</div>
							}
						</Group>
					: null 
				}
			</Panel>
		)
	}
}

export default HomeStartPage;