/* Меню Табс*/
import React from 'react';
import './style.css';

class MenuTabs extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			activeItem: this.props.activeItem,
		};
	}

	changeMenu = (item) => {
		this.setState({
			activeItem: item,
		})
	}

	getMenu = (children) => {
		let { activeItem } = this.state
		return (
			children.map((item, i) => {
				let { title, id } = item.props

				return (
					<div 
						onClick={() => {
							this.changeMenu(id)
						}} 
						className={`titleContainer ${(activeItem === id) ? "active" : ""}`} 
						key={i}
					>
						{title}
					</div>
				)

			})
		)
	}

	getContent = (children) => {
		let { activeItem } = this.state
		return children.find(({props}) => {
			return (props.id === activeItem) ? true : false
		})
	}

	render() {
		let { children } = this.props
		return (
			<div>
				<div className="mainMenuContainer horizontal-scroll-wrapper">
					{ this.getMenu(children) }
				</div>
				<div className="mainContentContainer">
					{ this.getContent(children) }
				</div>
			</div>
		)
	}
}

export default MenuTabs;