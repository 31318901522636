import React from 'react';
import { Panel, Group, Div, Button } from '@vkontakte/vkui';
import Header from '../../components/Header/Header';

import app from '../../func/app';
import navigation from '../../func/navigation';

class VerifyAccount extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	goVerifyAccount = () => {
		app.setState({
			isFake: false
		}, () => {
			navigation.goBack()
		})
	}

	render() {
		return (
			<Panel theme="white" id={this.props.id}>
				<Header panelBack={navigation.goBack} name="Идентификация"/>
				<Group>
					<Div>
						<b>Пройти фейк-контроль просто!</b>
					</Div>
					<Div className="pt-0">
						Фиксированный порог прохождения фейк-контроля символически составляет 250 руб, которые Вы всегда сможете вернуть, если статус «Фейк-контроль пройден» станет Вам больше не нужен.
					</Div>
						{
							/*
					<Div className="pt-0">
						Сразу после перечисления, Ты получаешь статус «Фейк-контроль пройден».
					</Div>
					<Div className="pt-0">
						<b>Ты ничего не потеряешь потому, что:</b>
					</Div>
					<Div className="pt-0">
						Когда статус «Фейк-контроль пройден» станет Тебе не нужен, Ты сможешь запросить возврат этих средств
					</Div>
							*/
						}
					<Div className="flex_end pt-0">
						<Button onClick={this.goVerifyAccount}>Пройти проверку</Button>
					</Div>
				</Group>
			</Panel>
		)
	}
}

export default VerifyAccount;