import React from 'react';
import { Panel, Spinner, List, Group, Cell } from '@vkontakte/vkui';
import Header from '../../components/Header/Header';

import CellBlock from '../../components/CellBlock/CellBlock';

//import app from '../../func/app';
import navigation from '../../func/navigation';
import { back_getOneGroup } from '../../func/back';



class GroupInfo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoad: false,
			target: this.props.targetGroup,
			infoGroup: {}
		};
	}

	componentDidMount() {
		//запрос на получение информации из группы
		const { target } = this.state
		const { id } = target
		back_getOneGroup(id, (response) => {
			this.setState({
				infoGroup: response.response,
				isLoad: true,
			})
		})
	}


	render() {
		console.log(this.state)
		let { isLoad, target, infoGroup } = this.state
		let rating = infoGroup.validMembers / target.members_count * 100
		return (
			<Panel theme="white" id={this.props.id}>
				<Header panelBack={navigation.goBack} name="Статистика"/>
				{
					(isLoad) ? 
						<div>
							<CellBlock
								photo_50={target.photo_50}
								title={target.name}
							/>
							<Group>
								<List>
									<Cell>
										Всего участников: {target.members_count}
									</Cell>
									<Cell>
										Прошедших фейк-контроль: {infoGroup.validMembers}
									</Cell>
									<Cell>
										Рейтинг доверия: {`${rating.toFixed(3)}%`}
									</Cell>
								</List>
							</Group>
						</div>
					: <Spinner size="regular" style={{ marginTop: 20 }} />
				}
			</Panel>
		)
	}
}

export default GroupInfo;