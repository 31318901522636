import 'core-js/es6/map';
import 'core-js/es6/set';
import React from 'react';
import ReactDOM from 'react-dom';
import connect from '@vkontakte/vkui-connect';
import App from './App';
import BadVersion from './components/BadVersion/BadVersion';
// import registerServiceWorker from './sw';
import { getParams, getAndroidVersion } from './func/utils';

//css
import './style.css';

// Init VK App
connect.send('VKWebAppInit', {});

// Если вы хотите, чтобы ваше веб-приложение работало в оффлайне и загружалось быстрее,
// расскомментируйте строку с registerServiceWorker();
// Но не забывайте, что на данный момент у технологии есть достаточно подводных камней
// Подробнее про сервис воркеры можно почитать тут — https://vk.cc/8MHpmT 
// registerServiceWorker();

switch (getParams().vk_platform) {
	case 'desktop_web':
		ReactDOM.render(<App />, document.getElementById('root'));
	break;
	case 'mobile_android':
		if (Number(String(getAndroidVersion()).split('.')[0]) < 5) {
			ReactDOM.render(<BadVersion />, document.getElementById('root'));
		} else {
			ReactDOM.render(<App />, document.getElementById('root'));
		}
	break;
	default:
		ReactDOM.render(<App />, document.getElementById('root'));
}