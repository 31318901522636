import React from 'react';
import { Panel, Div, Alert, Button, Group, List, Cell, Spinner } from '@vkontakte/vkui';
import Header from '../../components/Header/Header';

import app from '../../func/app';
import config from '../../config';
import navigation from '../../func/navigation';
import { back_getOneGroupAdmin } from '../../func/back';
import moment from "moment";
import { groupsGetById } from '../../func/vk';


class MyMoney extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoad: false,
			data: {},
			target: {},
		};
	}

	componentDidMount() {
		//запрос на получение информации
		const { vk_group_id } = config.params
		//получаем информацию о группе
		groupsGetById(config.access_token, +vk_group_id, (group) => {
			if (group.length > 0) {
				back_getOneGroupAdmin(+vk_group_id, (response) => {
					this.setState({
						data: response.response,
						isLoad: true,
						target: group[0],
					})
				})
			}
		}, (error) => {
			console.log(error)
		})
	}

	showAlert = () => {
		app.setState({ popout:
			<Alert
				actions={[{
					title: 'Отмена',
					autoclose: true,
					style: 'cancel'
				}, {
					title: 'Хорошо',
					autoclose: true,
				}]}
				onClose={() => {
					app.setState({popout: null})
				}}
			  >
				<h2>Отлично</h2>
				<p>Вся информация по выводу средств отправлена вам в личку</p>
			</Alert>
		});
	}

	howGetMymoney = () => {
		this.showAlert()
	}

	renderPayouts = () => {
		const { payouts } = this.state.data
		return (
			payouts.map((item, i) => {

				return <Cell key={i}>{moment(moment.unix(item.time), 'DD/MM/YYYY', true).format("DD.MM.YYYY HH:mm")} - 100р</Cell>
			})
		)
	}

	render() {
		let { isLoad, data, target } = this.state
		let rating = data.validMembers / target.members_count * 100
		//console.log(this.state)
		return (
			<Panel theme="white" id={this.props.id}>
				<Header panelBack={navigation.goBack} name="Доходы"/>
				{
					(isLoad) ?
						<div>
							<Div>
								Доходы сообщества {target.name}
							</Div>
							<Group>
								<List>
									<Cell>
										Всего участников: {target.members_count}
									</Cell>
									<Cell>
										Прошедших фейк-контроль: {data.validMembers}
									</Cell>
									<Cell>
										Рейтинг доверия: {`${rating.toFixed(3)}%`}
									</Cell>
								</List>
							</Group>
							<Group title="Последние выплаты">
								<List>
									{
										this.renderPayouts()
									}
								</List>
							</Group>
							<Group title="Общая сумма выплат">
								<Div>
									1123 руб.
								</Div>
							</Group>
							<Div className="flex_end pt-0">
								<Button onClick={this.howGetMymoney}>Запросить вывод</Button>
							</Div>
						</div>
					: <Spinner size="regular" style={{ marginTop: 20 }} />
				}
			</Panel>
		)
	}
}

export default MyMoney;